import $ from 'jquery'
import { processResponse } from '../../../site/behaviors/remote/process_response'
import './main.scss'

$(document).on('click', '.following__state', function (e) {
  e.stopPropagation()

  return $(this).closest('.following').toggleClass('following--showing-options')
})

$(document).on('click', '.following', (e) => e.stopPropagation())

$(document).on('click', () =>
  $('.following--showing-options').removeClass('following--showing-options')
)

$(document).on('click', '.following__option', function (_e) {
  const $option = $(this)
  const $following = $option.closest('.following')
  const $state = $following.find('.following__state')

  // For use by tests
  $following.attr('data-following-request-pending', '1')

  const name = $following.data('name')
  const value = $option.data('option')
  const label = $option.data('label')

  // Update the state.
  $following.toggleClass('following--following', value !== 'none')
  $state.find('.following__state__label').text(label)

  // Hide the panel.
  $following.removeClass('following--showing-options')

  // Issue a request.
  const data = {}
  data[name] = value
  data.authenticity_token = $following.data('authenticity-token')

  return $.ajax({
    type: 'PUT',
    url: $following.data('notification-subscriptions-url'),
    data,
  })
    .done(function (response) {
      processResponse(response)
      $following.attr('data-following-request-pending', null)
    })
    .fail((_xhr, _status, _error) =>
      alert('An error occurred updating your notification settings')
    )
})
